<template>
  <b-card-code>
    <div class="custom-search d-flex">
    <b-button variant="outline-primary" @click="showModal">
      {{ $t('Add New') }}
    </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="sliders"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: index -->
        <span v-else-if="props.column.field === 'image'">
          <img :src="props.row.image" alt="" width="50px" height="50px"/>
        </span>
        <span v-else-if="props.column.field === 'type'">
          {{ types.find(item=>item.id===parseInt(props.row.type))?types.find(item=>item.id===parseInt(props.row.type)).name:'' }}
        </span>
        <span v-else-if="props.column.field === 'description'">
          <div v-html="props.row.description?props.row.description.slice(0,100)+'...':''"></div>
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','20','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
        hide-footer
        :title="model_mode==='add'?$t('Add new slider'):$t('Edit Slider')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_new_slider">
          <b-row>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Title') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Title')"
                    rules="required"
                >
                  <b-form-input
                      v-model="title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write title here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Image') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Image')"
                    :rules="model_mode==='add'?required:''"
                >
                  <b-form-file
                      v-model="image"
                      name="image"
                      accept=".jpg, .png, .jpeg"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Select an image')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Type') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('type')"
                    :rules="required"
                >
                  <select class="form-control"
                      v-model.number="type_id"
                      name="type"
                      :state="errors.length > 0 ? false:null"
                  >
                    <option :value="null"> Select One</option>
                    <option v-for="(tp,key) in types" :value="tp.id" :key="key">{{tp.name }}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="type_id===2">
              <b-card-text>
                <span>{{ $t('Description') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('description')"
                    rules=""
                >
                  <vue-editor v-model="description"
                              name="description"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('write slider description here')"></vue-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-card-text></b-card-text>
              <b-button class="mt-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,BFormFile,
  BModal, BRow, BCol,BFormCheckbox,
} from 'bootstrap-vue'
import { VueEditor } from "vue2-editor";
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
//import {mapGetters} from "vuex";
export default {
  name:'Slider',
  components: {
    BCardCode,
    VueGoodTable,BCardText,BFormFile,
    BPagination,
    BFormGroup,BFormCheckbox,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,VueEditor,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      title: '',
      description: '',
      image: null,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Image',
          field: 'image',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      type_id:1,
      types:[
        {id:1, name:'Main Slider'},
        {id:2, name:'News Slider'},
        {id:3, name:'Boy Wing Slider'},
        {id:4, name:'Girls Wing Slider'},
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.selected_row=row;
      this.title=row.title;
      this.type_id=row.type;
      this.description=row.description;
      this.image=null;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_new_slider);
          data.append('description',this.description);
          if (this.model_mode ==='add'){
            apiCall.post('/slider/information/store',data).then((response)=>{
              this.hideModal();
              this.title='';
              this.image= null;
              this.description= '';
              this.$refs.simpleRules.reset();
              this.$toaster.success(response.data.message);
              this.$store.dispatch('GET_ALL_SLIDER');
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/slider/information/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SLIDER');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id){
      apiCall.put(`/slider/information/status/change${id}`).then((response)=>{
        this.$store.dispatch('GET_ALL_SLIDER');
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$store.dispatch('GET_ALL_SLIDER');
        this.$toaster.error(error.response.data.message);
      });
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/slider/information/remove${id}`).then((response)=>{
              this.$store.dispatch('GET_ALL_SLIDER');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.title='';
      this.type_id=1;
      this.image=null;
      this.description='';
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sliders(){
      return this.$store.getters.sliders;
    },
    //...mapGetters(['versions']),
  },
  created() {
    this.$store.dispatch('GET_ALL_SLIDER');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>